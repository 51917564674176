import { forEach, get, keys, pickBy } from 'lodash';
import { showVehicleV2page } from './accountUtils/vehicles';
import { isOrientCementAccount } from './accountUtils/common';
import { getAccountId } from './account_utils';
export const FORM_TEMPLATE_TYPES = {
    JOB_FORM: 'JOB_FORM',
    CONSIGNMENT_FORM: 'CONSIGNMENT_FORM',
    CONSIGNMENT_FORM_ERP: 'CONSIGNMENT_FORM_ERP',
    WORK_ORDER_FORM: 'WORK_ORDER_FORM',
    JOB_BOOKING_FORM: 'JOB_BOOKING_FORM',
    TRANSACTION_FORM: 'TRANSACTION_FORM',
    FREIGHT_BILL_FORM: 'FREIGHT_BILL_FORM',
    EPOD_FORM: 'EPOD_FORM',
    ISSUES_FORM: 'ISSUES_FORM',
    ORDER_MODULE_FORM: 'ORDER_MODULE_FORM',
    VEHICLE_FORM: 'VEHICLE_FORM',
};
export const FORM_TEMPLATE_TYPES_LABELS = {
    JOB_FORM: 'Job Form',
    CONSIGNMENT_FORM: 'Consignment Form',
    CONSIGNMENT_FORM_ERP: 'Consignment Form ERP',
    WORK_ORDER_FORM: 'Work Order Form',
    JOB_BOOKING_FORM: 'Job Booking Form',
    TRANSACTION_FORM: 'Transaction Form',
    FREIGHT_BILL_FORM: 'Freight Bill Form',
    EPOD_FORM: 'EPOD Form',
    ISSUES_FORM: 'Issues Form',
    ORDER_MODULE_FORM: 'Order Module Form',
    VEHICLE_FORM: 'Vehicle Form',
};
export const getCreateTemplateLink = (formType) => {
    switch (formType) {
        case 'JOB_FORM':
            return '/dashboard/routes/jobs/v2/add?isTemplate=true';
        case 'CONSIGNMENT_FORM':
            return '/dashboard/vendors/consignments/add?isTemplate=true';
        case 'ORDER_MODULE_FORM':
            return `/dashboard/order-module/add?isTemplate=true`;
        default:
            return '/dashboard/routes/jobs/v2/add?isTemplate=true';
    }
};
export const getEditTemplateLink = (formType, id) => {
    switch (formType) {
        case 'JOB_FORM':
            return `/dashboard/routes/jobs/v2/edit/${id}?isTemplate=true`;
        case 'CONSIGNMENT_FORM':
            return `/dashboard/vendors/consignments/edit/${id}?isTemplate=true`;
        case 'CONSIGNMENT_FORM_ERP':
            return `/dashboard/consignments/edit/${id}/TEMPLATE?isTemplate=true`;
        case 'WORK_ORDER_FORM':
            return `/dashboard/workorders/edit/${id}?isTemplate=true`;
        case 'JOB_BOOKING_FORM':
            return `/dashboard/routes/jobs/bookings/edit/${id}?isTemplate=true`;
        case 'TRANSACTION_FORM':
            return `/dashboard/finance/transactions/edit/${id}?isTemplate=true`;
        case 'FREIGHT_BILL_FORM':
            return `/dashboard/bills/freightBill/edit/${id}?isTemplate=true`;
        case 'EPOD_FORM':
            return `/dashboard/epod/epod-form/edit/${id}?isTemplate=true`;
        case 'ISSUES_FORM':
            return `/dashboard/issues/edit/${id}?isTemplate=true`;
        case 'ORDER_MODULE_FORM':
            return `/dashboard/order-module/edit/${id}?isTemplate=true`;
        case 'VEHICLE_FORM':
            const link = showVehicleV2page() || isOrientCementAccount(getAccountId())
                ? `/dashboard/vehicles/v2/edit/${id}?isTemplate=true`
                : `/dashboard/vehicles/edit/${id}?isTemplate=true`;
            return link;
        default:
            return '/dashboard/routes/jobs/v2/edit/';
    }
};
export function validateFieldRequired({ value, fieldsMetaData, id }) {
    if (fieldsMetaData && fieldsMetaData[id] && fieldsMetaData[id].required && !value) {
        return false;
    }
    return true;
}
export function validateTemplateRequiredFields(values, fieldsMetaData) {
    const allRequiredFields = keys(pickBy(fieldsMetaData, (value) => value.required === true));
    const errors = {};
    forEach(allRequiredFields, (field) => {
        if (!get(values, field)) {
            errors[field] = 'Required';
        }
    });
    return errors;
}
